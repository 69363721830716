<template lang="pug">
div
</template>

<script>
export default {
    data() {
        return {
            item: null,
            data: null,
        };
    },
    beforeMount() {
    },
};
</script>