<template lang="pug">
div TEST
</template>
<script>
export default {
    data() {
        return {
            data:null,
        };
    },
    beforeMount() {
    },
    methods:{
    }
};
</script>