<template lang="pug">
div

    // REMINDER
    div.pt-1(v-if="data.type=='reminder'")
        div(v-if="data.data.companyId")
            label.mb-0() Client
            p.mb-50(style="font-weight:500")  {{ company ? company.name : "n.d" }}
        div(v-if="data.data.comments")
            label.mb-0 Commentaire
            p.mb-50(style="font-weight:500" v-html="formatMessage(data.data.comments)")
        ejs-button(v-if="checkFunctionUser(100, 'all')" v-on:click.native="newActivity()" :content="'+ Créer une activité commerciale'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="' e-btn-sb-icons e-icons e-calculated-member'" cssClass="w-100 e-outline" iconPosition="Right")
    
    // PLANNING
    div.pt-1(v-else-if="data.type=='planning'")
        label.mb-0 Type d'évènement
        .d-flex.align-items-center.mb-50
            div.pin-color.small(:style="'background-color:'+( eventType && eventType.color!=='' ? eventType.color : '#0c3571')+' !important'")
            div {{ eventType ? eventType.label : "n.d" }}
        div(v-if="data.data.companyId")
            label.mb-0() Client
            p.mb-50(style="font-weight:500")  {{ company ? company.name : "n.d" }}
        div(v-if="data.data.affairId")
            label.mb-0 Affaire
            .d-flex.align-items-center.mb-50
                div.pin-color.small(:style="'background-color:'+( affair && affair.color!=='' ? affair.color : '#0c3571')+' !important'")
                div {{ affair ? affair.displayLabel : "n.d" }}
        div(v-if="data.data.missionId")
            label.mb-0 Mission
            p.mb-50(style="font-weight:500") {{ mission ? mission.label : "n.d" }}
        label.mb-0 Nom
        p.mb-50(style="font-weight:500")  {{ data.name }}
        label.mb-0 Durée
        p.mb-50(style="font-weight:500") {{ data.isAllDay ? Math.ceil(data.data.duration) + ' jour(s) - ' + data.data.hoursDay+ ' h/jour' : data.data.duration + ' h' }}
        //- p.mb-50(style="font-weight:500") {{ data.data.duration !== data.data.hoursDay ? data.data.hoursDay + ' h/jour' : data.data.duration + ' h'  }}
        //- {{  data.data.duration && data.data.duration == 24 ? '1 jour' + (data.data.hoursDay ? ' - '+ data.data.hoursDay+ ' h/jour' : '') :  data.data.duration && data.data.hoursDay ? Math.ceil(data.data.hoursDay) + ' jours - ' + data.data.duration + ' h' :  data.data.duration  ? data.data.duration + ' h' : ''  }}
        //- {{ data.data.hoursDay ? data.data.hoursDay+ ' h/jour' + (data.data.duration ? ' '+ Math.ceil(data.data.duration) + ' jours' : '') : data.data.duration  ? data.data.duration + ' h' : '' }} 
        div(v-if="data.data.comments")
            label.mb-0 Commentaire
            p.mb-50(style="font-weight:500" v-html="formatMessage(data.data.comments)")
    // Intervention
    div.pt-1(v-else-if="data.type=='intervention'")
        div(v-if="data.data.companyId")
            label.mb-0() Client
            p.mb-50(style="font-weight:500")  {{ company ? company.name : "n.d" }}
        div(v-if="data.data.affairId")
            label.mb-0 Affaire
            .d-flex.align-items-center.mb-50
                div.pin-color.small(:style="'background-color:'+( affair && affair.color!=='' ? affair.color : '#0c3571')+' !important'")
                div {{ affair ? affair.displayLabel : "n.d" }}
        div(v-if="data.data.missionId")
            label.mb-0 Mission
            p.mb-50(style="font-weight:500") {{ mission ? mission.label : "n.d" }}
        label.mb-0 Nom
        p.mb-50(style="font-weight:500")  {{ data.name }}
        label.mb-0 Durée
        p.mb-50(style="font-weight:500") {{ getDurationDisplay(data) }}
        //- p.mb-50(style="font-weight:500") {{ data.data.duration !== data.data.hoursDay ? data.data.hoursDay + ' h/jour' : data.data.duration + ' h'  }}
        //- {{  data.data.duration && data.data.duration == 24 ? '1 jour' + (data.data.hoursDay ? ' - '+ data.data.hoursDay+ ' h/jour' : '') :  data.data.duration && data.data.hoursDay ? Math.ceil(data.data.hoursDay) + ' jours - ' + data.data.duration + ' h' :  data.data.duration  ? data.data.duration + ' h' : ''  }}
        //- {{ data.data.hoursDay ? data.data.hoursDay+ ' h/jour' + (data.data.duration ? ' '+ Math.ceil(data.data.duration) + ' jours' : '') : data.data.duration  ? data.data.duration + ' h' : '' }} 
        div(v-if="data.data.comments")
            label.mb-0 Commentaire
            p.mb-50(style="font-weight:500" v-html="formatMessage(data.data.comments)")        

    // REPORTING
    div.pt-1(v-else-if="data.type=='reporting'")
        div(v-if="data.data.affairId")
            label.mb-0 Affaire
            //- p.mb-50(style="font-weight:500")  {{ affair ? affair.displayLabel : "n.d" }}
            .d-flex.align-items-center.mb-50
                div.pin-color.small(:style="'background-color:'+( affair && affair.color!=='' ? affair.color : '#0c3571')+' !important'")
                div {{ affair ? affair.displayLabel : "n.d" }}
        div(v-if="data.data.missionId")
            label.mb-0 Mission
            p.mb-50(style="font-weight:500")  {{ mission ? mission.label : "n.d" }}
        label.mb-0 Durée
        p.mb-50(style="font-weight:500")  {{ data.data.duration }} h
        label.mb-0 Commentaire
        p.mb-50(style="font-weight:500" v-html="formatMessage(data.data.message)")
    div(v-else)
        ejs-button(v-if="checkFunctionUser(107, 'all')" :class="checkFunctionUser(100, 'all') || checkFunctionUser(121, 'all') || checkFunctionUser(92, 'all') ? 'mb-1' : ''" v-on:click.native="newEvent()" :content="'+ Ajouter un évènement'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-timeline-today'" cssClass="w-100" iconPosition="Right")
        ejs-button(v-if="checkFunctionUser(92, 'all')" :class="checkFunctionUser(100, 'all') || checkFunctionUser(121, 'all') ? 'mb-1' : ''" v-on:click.native="newReport(data)" :content="'+ Créer mon reporting'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-changes-accept'" cssClass="w-100" iconPosition="Right" style="opacity:0.8")
        ejs-button(v-if="checkFunctionUser(121, 'all')" :class="checkFunctionUser(100, 'all') ? 'mb-1' : ''" v-on:click.native="newIntervention()" :content="'+ Créer mon intervention'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-changes-accept'" cssClass="w-100" iconPosition="Right" style="opacity:0.8")
        ejs-button(v-if="checkFunctionUser(100, 'all')" v-on:click.native="newActivity()" :content="'+ Créer une activité commerciale'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="' e-btn-sb-icons e-icons e-calculated-member'" cssClass="w-100 e-outline" iconPosition="Right")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { checkFunctionUser } from "@/auth/utils.ts";

export default {
  data() {
    return {
      data: null,
      eventType: null,
      company: null,
      affair: null,
      mission: null,
    };
  },
  computed: {
    ...mapGetters([
      "eventTypesList",
      "companiesList",
      "affairsList",
      "missionsList",
    ]),
  },
  beforeMount() {
    if (this.data.data) {
      this.eventType =
        this.data.data.eventTypeId &&
        this.eventTypesList.find(
          (eventType) => eventType.id == this.data.data.eventTypeId
        )
          ? this.eventTypesList.find(
              (eventType) => eventType.id == this.data.data.eventTypeId
            )
          : "";
      if (this.data.data.affairId) {
        this.affair =
          this.data.data.affairId &&
          this.affairsList.find(
            (affair) => affair.id == this.data.data.affairId
          )
            ? this.affairsList.find(
                (affair) => affair.id == this.data.data.affairId
              )
            : "";
      }
      if (this.data.data.companyId) {
        this.company =
          this.data.data.companyId &&
          this.companiesList.find(
            (company) => company.id == this.data.data.companyId
          )
            ? this.companiesList.find(
                (company) => company.id == this.data.data.companyId
              )
            : "";
      }
      if (this.data.data.missionId) {
        this.mission =
          this.data.data.missionId &&
          this.missionsList.find(
            (mission) => mission.id == this.data.data.missionId
          )
            ? this.missionsList.find(
                (mission) => mission.id == this.data.data.missionId
              )
            : "";
      }
    }
  },
  methods: {
    checkFunctionUser,
    getDurationDisplay(data) {
      if (data.isAllDay) {
        // Si la durée est en jours
        let days = Math.ceil(data.data.duration / 24);
        return `${days} jour${days > 1 ? "s" : ""}`;
      } else {
        // Si la durée est en heures
        return `${data.data.duration} h`;
      }
    },
    formatMessage(str) {
      return str ? str.replace(/\r?\n|\r/g, "<br>") : "";
    },
    newEvent() {
      let dataNewEvent = {
        endTime: this.data.endDate,
        startTime: this.data.startDate,
      };
      this.$parent.$parent.$refs.ScheduleObj.openEditor(dataNewEvent, "Add");
      // this.parent.openEditor
    },
    newReport(data) {
      this.$router.push({
        name: "my-reports",
        params: {
          routeOrigine: "my-agenda",
          date: this.data.startDate,
        },
      });
    },
    newIntervention() {
      this.$router.push({
        name: "new-intervention",
        params: {
          routeOrigine: "my-agenda",
          date: this.data.startDate.toISOString(),
        },
      });
    },
    newActivity() {
      this.$router.push({
        name: "new-activity",
        params: {
          routeOrigine: "my-agenda",
          companyId:
            this.data.data && this.data.data.companyId
              ? this.data.data.companyId
              : 0,
        },
      });
    },
  },
};
</script>
<style>
.e-quick-popup-wrapper .e-cell-popup .e-popup-content {
  padding: 16px 18px 0px;
}
</style>
