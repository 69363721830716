<template lang="pug">
  div#dashboard.h-100
      .d-flex.flex-column
          .content-header.d-flex.justify-content-between.align-items-center.p-1
              h2.content-header-title.m-0.pr-1.mr-1.d-flex.align-items-center
                  span.material-icons-outlined.cursor-pointer(@click="setHomePage()" :class="currentHomePage ? 'text-primary' : ''" style="color: #ccc;") home
                  span.d-block.d-md-none Agenda
                  span.d-none.d-md-block Mon agenda
              .content-header-search-filter.mr-1.d-none.d-md-block(style="flex: 1;")
                  .position-relative.w-100.d-flex.align-items-center
                      .d-none.d-lg-block.mr-1
                          DateRanger(@setDates="setDates" :dateRangeDefault="dateRangeDefault" userFilter="myDashboardFilters" placeholder="Filtrer par période")
                      //- ejs-multiselect(cssClass="d-none d-lg-block ml-1" id='affairCollaborators' name="Collaborateurs" placeholder='Filtrer par collaborateur' popupHeight='300px' sortOrder="Ascending" :allowFiltering='true' :dataSource="collaboratorsList" filterType='Contains' :closePopupOnSelect="false" :fields="{ text: 'displayLabel', value: 'id' }" v-model='filterCollaborators')
                      ejs-multiselect(cssClass="d-none d-lg-block e-fixe-height mr-1" id='selectCompanies' name="Clients" placeholder='Filtrer par client/prospect' popupHeight='300px' sortOrder="Ascending" :allowFiltering='true' :dataSource="companiesList" filterType='Contains' :closePopupOnSelect="false" :fields="{ text: 'name', value: 'id' }" v-model='filterCompanies')
                      ejs-multiselect(cssClass="d-none d-lg-block e-fixe-height" id='affairAffairs' name="Affaires" placeholder='Filtrer par affaire' popupHeight='300px' sortOrder="Ascending" :allowFiltering='true' :dataSource="affairsList" filterType='Contains' :closePopupOnSelect="false" :fields="{ text: 'displayLabel', value: 'id' }" v-model='filterAffairs')
              .content-header-search.d-block.d-md-none
              .content-header-actions
                  .e-btn-group.e-lib.e-css(role='group')
                      input#viewCounted(type='radio' name='align' value="counted" v-model="viewMode" @click="changeViewDates(dateRangeDefault, true)")
                      label.e-btn.e-outline(for='viewCounted') Réél
                      input#viewCompare(type='radio' name='align' value="compare" v-model="viewMode" v-if="checkModule(6)" @click="changeViewDates(dateRangeDefault, true)")
                      label.e-btn.e-outline.border-primary(for='viewCompare') Réél vs Prévu
                      input#viewForecast(type='radio' name='align' value="forecast" v-model="viewMode" v-if="checkModule(6)" @click="changeViewDates(dateRangeDefault, true)")
                      label.e-btn.e-outline(for='viewForecast') Prévu
          b-col(cols='12').d-lg-none
              .w-100.mb-50
                  DateRanger(@setDates="setDates" :dateRangeDefault="dateRangeDefault" userFilter="myDashboardFilters" placeholder="Filtrer par période")
              //- ejs-dropdownlist(:showPopupButton='true' cssClass='ddl-icons-css w-100 d-md-none mb-50' :dataSource='viewList' placeholder='Sélectionnez une vue' :fields='iconFields' popupHeight='300px' v-model="viewType")
              ejs-multiselect(id='affairCollaborators' cssClass='w-100 e-fixe-height' name="Affaires" placeholder='Filtrer par affaire' popupHeight='300px' sortOrder="Ascending" :allowFiltering='true' :dataSource="affairsList" filterType='Contains' :closePopupOnSelect="false" :fields="{ text: 'displayLabel', value: 'id' }" v-model='filterAffairs')
      b-row.m-0.flex-lg-row-reverse(style="overflow: hidden; height: calc(100% - 60px)")
        b-col.d-block.d-lg-none(md="6" lg="6" xl="4")
          b-row(style="margin-right: -12px !important; margin-left: -12px !important;")
            b-col(sm="4" lg="6" xl="4" class="card-action col-4 mb-1" v-if="checkFunctionUser(92, 'all')")
              b-link(@click="newReport()" class="icon-add-planning")
                div Créer mon
                  br
                  span reporting
  
            b-col(sm="4" lg="6" xl="4" class="card-action col-4 mb-1" v-if="checkFunctionUser(121, 'all')")
              b-link(@click="newIntervention()" class="icon-add-planning")
                div Créer mon
                  br
                  span intervention
        b-col(md="12" lg="6" xl="9").h-100
            .cart-chart.mb-0.h-100(v-if="!dataLoaded")
                h4.mb-0.position-absolute Mon Agenda
                div.text-center.flex-center.h-100(style="height:430px")
                    b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
            my-scheduler(v-else :dataScheduler="dataSchedulerFiltered" :currentView="currentView" :currentDate="currentDate" :viewMode="viewMode" @changeCurrentView="changeCurrentView" @changeViewDates="changeViewDates")
        b-col(md="12" lg="6" xl="3").d-flex.flex-column.flex-lg-column-reverse.h-100
            .cart-chart.mb-0.h-100(style='overflow: auto;')
                .h-100
                    div.pb-1(v-if="dataAffairsFiltered.length>0")
                        h4.d-flex.align-items-center
                            span.material-icons-outlined.mr-1(style="font-size:16px") work
                            | Mes projets
                        .w-100.mb-1(v-for="(affair, indexAffair) of dataAffairsFiltered" :key="indexAffair")
                            .card-list-left.pt-50.pb-50.h-100.bg-light-grey(style="box-shadow: none !important;" :style="'border-color:' + affair.color")
                                .d-flex.w-100.justify-content-between(style='font-weight:500')
                                    span {{ affair.affairLabel }}
                                    div(style="min-width:fit-content; font-weight:600")
                                        span {{ affair.durationCompleted % 1 !== 0 ? affair.durationCompleted.toFixed(2) : affair.durationCompleted }} h
                                        span(v-if="affair.durationPlanned") / {{ Math.round(affair.durationPlanned * 100) / 100 }} h
                                .d-flex.w-100.mt-50(v-if="affair.durationPlanned" style="border-radius:4px; background:#cccc; height:8px")
                                    div(style="border-radius:4px; height:100%" :style="'width:'+(affair.durationCompleted/affair.durationPlanned*100)+'%; background:'+affair.color")
                    div.pb-1(v-else)
                        h4.d-flex.align-items-center
                            span.material-icons-outlined.mr-1(style="font-size:16px") work
                            | Aucun projet
                    div.pb-1(v-if="dataRemindersFilteredLate.length>0")
                        h4.d-flex.align-items-center
                            span.material-icons-outlined.text-dange.mr-1(style="font-size:16px") notifications
                            | Mes rappels
                            span.bg-danger.text-white.px-25.ml-50.rounded.small en retard
                        div(v-for="(groupDate, indexDate) of dataRemindersFilteredLate" :key="indexDate")
                            vs-divider(color="#cccccc" class="m-0 mt-1 mb-0 pl-0 text-primary" position="left")
                                h5 {{ groupDate.dateStr.charAt(0).toUpperCase() + groupDate.dateStr.slice(1) }}
                            .w-100.mb-1(v-for="(reminder, indexReminders) of groupDate.reminders" @click="newActivity(reminder.companyId)" :key="indexReminders")
                                .card-list-left.pt-50.pb-50.h-100.bg-light-grey(style="box-shadow: none !important; border-color:#dc3545")
                                    .d-flex.w-100.justify-content-between(style='font-weight:500')
                                        span {{ reminder.companyLabel }}
                                        div(style="min-width:fit-content; font-weight:600")
                                            span {{ reminder.dateStr }}
                    div.pb-1(v-else)
                        h4.d-flex.align-items-center
                            span.material-icons-outlined.text-danger.mr-1(style="font-size:16px") notifications
                            | Aucun rappel
                            span.bg-danger.text-white.px-25.ml-50.rounded.small en retard
                    div.pb-1(v-if="dataRemindersFilteredFuture.length>0")
                        h4.d-flex.align-items-center
                            span.material-icons-outlined.text-primary.mr-1(style="font-size:16px") notifications
                            | Mes rappels
                            span.bg-light.text-primary.border-primary.px-25.ml-50.rounded.small à venir
                        div(v-for="(groupDate, indexDate) of dataRemindersFilteredFuture" :key="indexDate")
                            vs-divider(color="#cccccc" class="m-0 mt-1 mb-0 pl-0 text-primary" position="left")
                                h5 {{ groupDate.dateStr.charAt(0).toUpperCase() + groupDate.dateStr.slice(1) }}
                            .w-100.mb-1(v-for="(reminder, indexReminders) of groupDate.reminders" @click="newActivity(reminder.companyId)" :key="indexReminders")
                                .card-list-left.pt-50.pb-50.h-100.bg-light-grey(style="box-shadow: none !important; border-color:#0c3571")
                                    .d-flex.w-100.justify-content-between(style='font-weight:500')
                                        span {{ reminder.companyLabel }}
                                        div(style="min-width:fit-content; font-weight:600")
                                            span {{ reminder.dateStr }}
                    div.pb-1(v-else)
                        h4.d-flex.align-items-center
                            span.material-icons-outlined.text-primary.mr-1(style="font-size:16px") notifications
                            | Aucun rappel
                            span.bg-light.text-primary.border-primary.px-25.ml-50.rounded.small à venir
  </template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatNumber } from "@/types/api-orisis/library/FormatOperations.ts";
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";

import { isMobile as checkIfMobile } from "@/types/api-orisis/library/GlobalOperations.ts";
import { checkFunctionUser } from "@/auth/utils.ts";
var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import DateRanger from "@/components/global/date-ranger/DateRanger.vue";
import MyScheduler from "@/components/planning/MyScheduler";

import { Timezone } from "@syncfusion/ej2-schedule";
let timezoneSchedule = new Timezone();

export default {
  components: {
    DateRanger,
    MyScheduler,
  },
  data() {
    return {
      activated: true,
      currentHomePage: false,
      title: "Synthèse",
      name: "myDashboard",
      tabActive: "all",
      selectedRows: [],
      dateRangeDefault: [
        new Date(dayjs().startOf("month").format("YYYY-MM-DD")),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")),
      ],
      filterAffairs: [],
      filterCompanies: [],
      currentDate: new Date(),
      currentView: "Month",

      dataPreLoaded: false,
      dataLoaded: false,
      isMobile: checkIfMobile(),

      viewMode: "compare",
      viewType: "vente",
      iconFields: { text: "text", iconCss: "iconCss", value: "name" },
    };
  },
  computed: {
    ...mapGetters([
      "eventsListByCollaborator",
      "reportDetailsListByCollaborator",
      "interventionsListByCollaboratorId",
      "remindersListByCollaborator",
      "eventTypesList",
      "payrollEventsList",
      "userCollaboratorId",
      "collaboratorsList",
      "affairsList",
      "missionsList",
      "workspaceSelected",
      "userParametersList",
      "userCollaborator",
      "companiesList",
    ]),
    dataScheduler() {
      let dataSchedulerMapped = [];
      dataSchedulerMapped = this.eventsListByCollaborator.map((event) => {
        return {
          ...event,
          type: "planning",
          data: {
            ...event,
            hoursDay:
              dayjs(event.endDate).diff(event.startDate, "hours", true) < 24
                ? dayjs(event.endDate).diff(event.startDate, "hours", true)
                : event.hoursDay == null
                ? event.duration
                : event.hoursDay,
            duration:
              dayjs(event.endDate).diff(event.startDate, "hours", true) >= 24
                ? dayjs(event.endDate).diff(event.startDate, "days", true)
                : event.duration,
          },
          id: event.id,
          name: this.affairsList.find((affair) => affair.id == event.affairId)
            ? this.affairsList.find((affair) => affair.id == event.affairId)
                .displayLabel
            : event.eventTypeId &&
              this.eventTypesList.find(
                (eventType) => eventType.id == event.eventTypeId
              )
            ? this.eventTypesList.find(
                (eventType) => eventType.id == event.eventTypeId
              ).label
            : "Event " + event.id,
          isAllDay:
            dayjs(event.endDate).diff(event.startDate, "days", true) >= 1
              ? true
              : false,
          startDate: event.startDate,
          endDate: event.endDate,
          color: this.affairsList.find((affair) => affair.id == event.affairId)
            ? this.affairsList
                .find((affair) => affair.id == event.affairId)
                .color.substring(0, 7)
            : event.eventTypeId &&
              this.eventTypesList.find(
                (eventType) => eventType.id == event.eventTypeId
              )
            ? this.eventTypesList.find(
                (eventType) => eventType.id == event.eventTypeId
              ).color
            : "#82868b",
        };
      });

      this.reportDetailsListByCollaborator.forEach((report) => {
        dataSchedulerMapped.push({
          type: "reporting",
          affairId: report.affairId ? report.affairId : null,
          data: report,
          status: report.status,
          id: report.id,
          name: report.affairId
            ? this.affairsList.find((affair) => affair.id == report.affairId)
              ? "✓ " +
                this.affairsList.find((affair) => affair.id == report.affairId)
                  .displayLabel
              : "✔ Affaire " + report.affairId
            : report.payrollEventId
            ? this.payrollEventsList.find(
                (payrollEvent) => payrollEvent.id == report.payrollEventId
              )?.label
            : "✔ " + "Reporting",
          isAllDay: true,
          startDate: report.startDate,
          endDate: report.endDate,
          color: this.affairsList.find((affair) => affair.id == report.affairId)
            ? this.affairsList
                .find((affair) => affair.id == report.affairId)
                .color.substring(0, 7)
            : "#82868b",
        });
      });

      this.interventionsListByCollaboratorId.forEach((intervention) => {
        dataSchedulerMapped.push({
          type: "intervention",
          id: intervention.id,
          data: intervention,
          startDate: intervention.date,
          endDate: intervention.date,
          isAllDay: false,
          color: this.affairsList.find(
            (affair) => affair.id == intervention.affairId
          )
            ? this.affairsList
                .find((affair) => affair.id == intervention.affairId)
                .color.substring(0, 7)
            : "#82868b",
          affairId: intervention.affairId,
          name: this.affairsList.find(
            (affair) => affair.id == intervention.affairId
          )
            ? "🔧 " +
              this.affairsList.find(
                (affair) => affair.id == intervention.affairId
              ).displayLabel
            : "🔧 Affaire " + intervention.affairId,
        });
      });

      this.remindersListByCollaborator.forEach((reminder) => {
        dataSchedulerMapped.push({
          type: "reminder",
          affairId: null,
          data: reminder,
          status: null,
          id: reminder.id,
          name: this.companiesList.find(
            (company) => company.id == reminder.companyId
          )
            ? "🔔 " +
              this.companiesList.find(
                (company) => company.id == reminder.companyId
              ).name
            : "🔔 Prospect/Client " + reminder.companyId,
          isAllDay: false,
          startDate: dayjs(reminder.reminderDate).format("YYYY-MM-DDTHH:mm:ss"),
          endDate: dayjs(reminder.reminderDate)
            .add(15, "minutes")
            .format("YYYY-MM-DDTHH:mm:ss"),
          color:
            dayjs(reminder.reminderDate).format("YYYY-MM-DDTHH:mm:ss") <
            dayjs(new Date()).format("YYYY-MM-DDTHH:mm:ss")
              ? "#dc3545"
              : "#0c3571",
        });
      });

      return dataSchedulerMapped.sort((a, b) => a.name - b.name);
    },
    dataSchedulerFiltered() {
      if (this.viewMode == "counted") {
        let dataFiltered = this.dataScheduler.filter(
          (el) => el.type == "reporting" || el.type == "intervention"
        );
        if (this.filterCompanies.length > 0) {
          dataFiltered = dataFiltered.filter((el) =>
            this.filterCompanies.includes(el.companyId)
          );
        }
        if (this.filterAffairs.length > 0) {
          dataFiltered = dataFiltered.filter((el) =>
            this.filterAffairs.includes(el.affairId)
          );
        }
        return dataFiltered;
      } else if (this.viewMode == "forecast") {
        let dataFiltered = this.dataScheduler.filter(
          (el) =>
            el.type == "planning" ||
            el.type == "reminder" ||
            el.type == "intervention"
        );
        if (this.filterCompanies.length > 0) {
          dataFiltered = dataFiltered.filter((el) =>
            this.filterCompanies.includes(el.companyId)
          );
        }
        if (this.filterAffairs.length > 0) {
          dataFiltered = dataFiltered.filter((el) =>
            this.filterAffairs.includes(el.affairId)
          );
        }
        return dataFiltered;
      } else {
        let dataFiltered = this.dataScheduler;
        if (this.filterCompanies.length > 0) {
          dataFiltered = dataFiltered.filter((el) =>
            this.filterCompanies.includes(el.companyId)
          );
        }
        if (this.filterAffairs.length > 0) {
          dataFiltered = dataFiltered.filter((el) =>
            this.filterAffairs.includes(el.affairId)
          );
        }
        return dataFiltered;
      }
    },
    dataAffairs() {
      let dataAffairsMapped = [];

      this.eventsListByCollaborator.forEach((event) => {
        if (
          event.affairId &&
          event.affairId > 0 &&
          this.affairsList.find((affair) => affair.id == event.affairId)
        ) {
          if (
            dataAffairsMapped.find(
              (dataAffair) => dataAffair.affairId == event.affairId
            )
          ) {
            let durationDays = dayjs(event.endDate).diff(
              event.startDate,
              "days",
              true
            );
            let durationHours = dayjs(event.endDate).diff(
              event.startDate,
              "hours",
              true
            );

            let durationPlanned = 0;
            if (durationDays >= 1) {
              durationPlanned = event.hoursDay
                ? event.hoursDay * durationDays
                : durationDays *
                  (this.userCollaborator
                    ? this.userCollaborator.hoursByDay
                    : 1);
            } else {
              durationPlanned = event.hoursDay ? event.hoursDay : durationHours;
            }
            dataAffairsMapped.splice(
              dataAffairsMapped.findIndex(
                (dataAffair) => dataAffair.affairId == event.affairId
              ),
              1,
              {
                ...dataAffairsMapped.find(
                  (dataAffair) => dataAffair.affairId == event.affairId
                ),
                durationPlanned:
                  dataAffairsMapped.find(
                    (dataAffair) => dataAffair.affairId == event.affairId
                  ).durationPlanned + durationPlanned,
              }
            );
          } else {
            let durationDays = dayjs(event.endDate).diff(
              event.startDate,
              "days",
              true
            );
            let durationHours = dayjs(event.endDate).diff(
              event.startDate,
              "hours",
              true
            );

            let durationPlanned = 0;
            if (durationDays >= 1) {
              durationPlanned = event.hoursDay
                ? event.hoursDay * durationDays
                : durationDays *
                  (this.userCollaborator
                    ? this.userCollaborator.hoursByDay
                    : 1);
            } else {
              durationPlanned = event.hoursDay ? event.hoursDay : durationHours;
            }
            dataAffairsMapped.push({
              companyId: event.companyId,
              companyLabel: this.companiesList.find(
                (company) => company.id == event.companyId
              )
                ? this.companiesList.find(
                    (company) => company.id == event.companyId
                  ).name
                : "Société " + event.companyId,
              affairId: event.affairId,
              affairLabel: this.affairsList.find(
                (affair) => affair.id == event.affairId
              )
                ? this.affairsList.find((affair) => affair.id == event.affairId)
                    .displayLabel
                : "Affaire " + event.affairId,
              color: this.affairsList.find(
                (affair) => affair.id == event.affairId
              )
                ? this.affairsList
                    .find((affair) => affair.id == event.affairId)
                    .color.substring(0, 7)
                : event.eventTypeId &&
                  this.eventTypesList.find(
                    (eventType) => eventType.id == event.eventTypeId
                  )
                ? this.eventTypesList.find(
                    (eventType) => eventType.id == event.eventTypeId
                  ).color
                : "#82868b",
              durationPlanned: durationPlanned,
              durationCompleted: 0,
            });
          }
        }
      });

      this.reportDetailsListByCollaborator.forEach((report) => {
        if (report.affairId && report.affairId > 0) {
          let affair = this.affairsList.find(
            (affair) => affair.id == report.affairId
          );
          let duration = report.duration;
          this.updateAffairDuration(
            dataAffairsMapped,
            report,
            affair,
            duration,
            "completed"
          );
        }
      });

      return dataAffairsMapped.sort(
        (a, b) => b.durationCompleted - a.durationCompleted
      );
    },
    dataAffairsFiltered() {
      let dataFiltered = this.dataAffairs;
      if (this.filterCompanies.length > 0) {
        dataFiltered = dataFiltered.filter((el) =>
          this.filterCompanies.includes(el.companyId)
        );
      }
      if (this.filterAffairs.length > 0) {
        dataFiltered = dataFiltered.filter((el) =>
          this.filterAffairs.includes(el.affairId)
        );
      }
      return dataFiltered;
    },
    dataReminders() {
      let dataRemindersMapped = [];
      this.remindersListByCollaborator.forEach((reminder) => {
        dataRemindersMapped.push({
          companyId: reminder.companyId,
          companyLabel: this.companiesList.find(
            (company) => company.id == reminder.companyId
          )
            ? this.companiesList.find(
                (company) => company.id == reminder.companyId
              ).name
            : "Prospect/Client " + reminder.companyId,
          date: new Date(reminder.reminderDate),
          dateStr: dayjs(reminder.reminderDate).format("HH:mm"),
          // color : this.affairsList.find(affair=> affair.id==schedule.affairId) ? this.affairsList.find(affair=> affair.id==schedule.affairId).color.substring(0, 7) : "#82868b",
          // durationPlanned:0,
          // durationCompleted:schedule.duration
        });
      });
      return dataRemindersMapped;
    },
    dataRemindersFiltered() {
      if (this.filterCompanies.length > 0) {
        return this.dataReminders
          .filter((el) => this.filterCompanies.includes(el.companyId))
          .reduce((acc, obj) => {
            // On crée un object par affaire
            let findIndex = acc.findIndex(
              (el) => el.date == dayjs(obj.date).format("YYYY-MM-DD")
            );
            if (findIndex < 0) {
              // On instancie
              acc.push({
                date: dayjs(obj.date).format("YYYY-MM-DD"),
                dateStr: dayjs(obj.date).format("dddd D MMMM YYYY"),
                reminders: [obj],
              });
            } else {
              // On additionne
              acc[findIndex]["reminders"].push(obj);
            }
            return acc;
          }, [])
          .map((event) => {
            return {
              ...event,
              reminders: event.reminders.sort((a, b) => a.date - b.date),
            };
          })
          .sort((a, b) => b.date - a.date);
      } else {
        return this.dataReminders
          .reduce((acc, obj) => {
            // On crée un object par affaire
            let findIndex = acc.findIndex(
              (el) => el.date == dayjs(obj.date).format("YYYY-MM-DD")
            );
            if (findIndex < 0) {
              // On instancie
              acc.push({
                date: dayjs(obj.date).format("YYYY-MM-DD"),
                dateStr: dayjs(obj.date).format("dddd D MMMM YYYY"),
                reminders: [obj],
              });
            } else {
              // On additionne
              acc[findIndex]["reminders"].push(obj);
            }
            return acc;
          }, [])
          .map((event) => {
            return {
              ...event,
              reminders: event.reminders.sort((a, b) => a.date - b.date),
            };
          })
          .sort((a, b) => b.date - a.date);
      }
    },
    dataRemindersFilteredLate() {
      return this.dataRemindersFiltered
        .filter(
          (el) =>
            dayjs(el.date).format("YYYY-MM-DDTHH:mm:ss") <
            dayjs(new Date()).format("YYYY-MM-DDTHH:mm:ss")
        )
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    },
    dataRemindersFilteredFuture() {
      return this.dataRemindersFiltered
        .filter(
          (el) =>
            dayjs(el.date).format("YYYY-MM-DDTHH:mm:ss") >=
            dayjs(new Date()).format("YYYY-MM-DDTHH:mm:ss")
        )
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    },
  },
  async created() {
    this.activated = false;
    await this.getMissions({});
    await this.getAffairs({});
    await this.getEventTypes({});
    await this.getPayrollEvents({});
    await this.getCompanies({});
    this.getCollaborators({});
    if (
      this.userParametersList.find((el) => el.key == "homePage") &&
      this.userParametersList.find((el) => el.key == "homePage").value ==
        this.$route.path
    ) {
      this.currentHomePage = true;
    } else {
      this.currentHomePage = false;
    }
    if (
      this.userParametersList.find((el) => el.key == "mySchedulerCurrentView")
    ) {
      this.currentView = this.userParametersList.find(
        (el) => el.key == "mySchedulerCurrentView"
      ).value;
    }
    let filter = this.userParametersList.find(
      (el) => el.key == "myDashboardFilters"
    );
    if (filter) {
      this.setDates(JSON.parse(filter.value).dateRange);
    } else {
      await this.setDates({
        startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      });
    }
    this.activated = true;
  },
  async activated(args) {
    if (this.activated) {
      if (
        this.userParametersList.find((el) => el.key == "homePage") &&
        this.userParametersList.find((el) => el.key == "homePage").value ==
          this.$route.path
      ) {
        this.currentHomePage = true;
      } else {
        this.currentHomePage = false;
      }
      if (
        this.userParametersList.find((el) => el.key == "mySchedulerCurrentView")
      ) {
        this.currentView = this.userParametersList.find(
          (el) => el.key == "mySchedulerCurrentView"
        ).value;
      }
      let filter = this.userParametersList.find(
        (el) => el.key == "myDashboardFilters"
      );
      if (filter) {
        this.setDates(JSON.parse(filter.value).dateRange);
      } else {
        await this.setDates({
          startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
        });
      }
    }
  },
  methods: {
    ...mapActions([
      "createOrUpdateUserParameter",
      "getEventsByCollaboratorId",
      "getReportDetails",
      "getPayrollEvents",
      "getInterventionsByCollaboratorId",
      "getEventTypes",
      "getCompanies",
      "getCollaborators",
      "getMissions",
      "getAffairs",
      "getRemindersByCollaboratorId",
    ]),
    calculateDuration(startDate, endDate, hoursDay) {
      let durationDays = dayjs(endDate).diff(startDate, "days", true);
      let durationHours = dayjs(endDate).diff(startDate, "hours", true);

      if (durationDays >= 1) {
        return hoursDay
          ? hoursDay * durationDays
          : durationDays *
              (this.userCollaborator ? this.userCollaborator.hoursByDay : 1);
      } else {
        return hoursDay ? hoursDay : durationHours;
      }
    },
    updateAffairDuration(dataAffairsMapped, item, affair, duration, type) {
      let existingAffair = dataAffairsMapped.find(
        (dataAffair) => dataAffair.affairId == item.affairId
      );

      if (existingAffair) {
        existingAffair[
          `duration${type.charAt(0).toUpperCase() + type.slice(1)}`
        ] += duration;
      } else {
        dataAffairsMapped.push({
          companyId: affair.company ? affair.company.id : null,
          companyLabel: affair.company
            ? affair.company.name
            : `Société ${item.companyId}`,
          affairId: item.affairId,
          affairLabel: affair
            ? affair.displayLabel
            : `Affaire ${item.affairId}`,
          color: affair ? affair.color.substring(0, 7) : "#82868b",
          durationPlanned: type === "planned" ? duration : 0,
          durationCompleted: type === "completed" ? duration : 0,
        });
      }
    },
    formatNumber,
    formatCurrency,
    checkFunctionUser,
    setHomePage() {
      this.createOrUpdateUserParameter({
        label: "Page d'accueil",
        key: "homePage",
        value: this.$route.path,
      });
      this.currentHomePage = true;
    },
    newReport() {
      this.$router.push({
        name: "my-reports",
        params: {
          routeOrigine: "my-agenda",
          date: new Date().toISOString(),
        },
      });
    },
    newIntervention() {
      this.$router.push({
        name: "new-intervention",
        params: {
          routeOrigine: "my-agenda",
          date: new Date().toISOString(),
        },
      });
    },
    checkModule(moduleId) {
      return true;
    },
    changeCurrentView(view) {
      this.currentView = view;
      this.createOrUpdateUserParameter({
        label: "Vue de mon agenda par défaut",
        key: "mySchedulerCurrentView",
        value: view,
      });
    },
    changeViewDates(dates, refreshViewMode = false) {
      if (dates.force) {
        this.setDates(dates);
      } else if (refreshViewMode) {
        let startDate = this.dateRangeDefault[0];
        let endDate = this.dateRangeDefault[1];
        this.setDates({ startDate: startDate, endDate: endDate });
      } else {
        let startDate = this.dateRangeDefault[0];
        let endDate = this.dateRangeDefault[1];
        if (this.dateRangeDefault[0] > dates.startDate) {
          startDate = dates.startDate;
        }
        if (this.dateRangeDefault[1] < dates.endDate) {
          endDate = dates.endDate;
        }
        if (
          this.dateRangeDefault[0] !== startDate &&
          this.dateRangeDefault[1] !== endDate
        ) {
          this.setDates({ startDate: startDate, endDate: endDate });
        }
      }
    },
    async setDates(dates) {
      if (dates.startDate) {
        this.dataLoaded = false;
        this.dataPreLoaded = false;
        this.currentDate = new Date(
          dayjs(
            this.dateDuMilieu(dayjs(dates.startDate), dayjs(dates.endDate))
          ).format("YYYY-MM-DD")
        );

        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];

        let allPromises = [
          checkFunctionUser(107, "read") && this.userCollaboratorId
            ? await this.getEventsByCollaboratorId({
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                collaboratorIds: [this.userCollaboratorId],
              })
            : null,
          checkFunctionUser(92, "read") && this.userCollaboratorId
            ? await this.getReportDetails({
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                collaboratorIds: [this.userCollaboratorId],
                route: "myDashboard",
              })
            : null,
          checkFunctionUser(121, "read") && this.userCollaboratorId
            ? await this.getInterventionsByCollaboratorId({
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                collaboratorId: this.userCollaboratorId,
              })
            : null,
          checkFunctionUser(100, "read") && this.userCollaboratorId
            ? await this.getRemindersByCollaboratorId({
                collaboratorId: this.userCollaboratorId,
              })
            : null,
        ];
        Promise.all(allPromises).then(() => {
          this.dataPreLoaded = true;
          this.dataLoaded = true;
        });
      }
    },
    dateDuMilieu(date1, date2) {
      // Assurez-vous que les entrées sont des objets dayjs
      if (!dayjs.isDayjs(date1) || !dayjs.isDayjs(date2)) {
        throw new Error("Les deux entrées doivent être des objets dayjs.");
      }

      // Calculez le milieu entre les deux dates
      const dateMilieu = date1.add(date2.diff(date1) / 2);

      return dateMilieu;
    },
    newActivity(companyId) {
      this.$router.push({
        name: "new-activity",
        params: {
          routeOrigine: "my-agenda",
          companyId: companyId ? companyId : 0,
        },
      });
    },
  },
};
</script>
