<template lang="pug">
  div
    v-style
      | .e-schedule-dialog .e-dlg-header-content {  background:{{(data.color ? data.color+'50' : '#f8f8f8')}} !important }
    div(style="background:#f8f8f8")
      div.px-1(:style="'background-color:'+(data.color ? data.color+'50' : '#f8f8f8')+' !important; border-bottom: 1px solid #dee2e6;'")
        b-row.content-scrollable-sticky.m-0.h-100.justify-content-center
          b-col#nav-sticky-horizontal(style="padding: 0 !important; height: 100% !important;" cols='12')
            ul.d-flex
              li
                a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'general' ? 'current' : ''" @click="tabActive = 'general'")
                  span Général
              li
                a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'details' ? 'current' : ''" @click="tabActive = 'details'")
                  span Détails
              //li(v-show="eventForm.collaborators && eventForm.collaborators.length>0")
                a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'notification' ? 'current' : ''" @click="tabActive = 'notification'")
                  span Notification
              li(style="position: absolute; right:0px")
                a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'settings' ? 'current' : ''" @click="tabActive = 'settings'")
                  span.material-icons-outlined(style="font-size: 21px !important;") settings
    div.px-1(v-show="tabActive == 'general'")
      b-row.mb-50.mt-1
        b-col.py-0(cols='12')
          input(type="hidden" name="type" id="type" v-model="data.type")
          b-form-group(label='Type d\'évènement *' label-for='eventType')
            ejs-dropdownlist(id="eventType" name="eventType" placeholder="Selectionnez un type d\'évènement" :itemTemplate="'itemTemplate'" :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="eventTypesList" :fields="{ text: 'label', value: 'id' }" v-model="data.eventTypeId" @change='changeEventType')
              template(v-slot:itemTemplate='{data}')
                .d-flex
                  div.pin-color(:style="'background-color:'+(data.color!=='' ? data.color : '#0c3571')+' !important'")
                  div {{data.label}}
              template(v-slot:footerTemplate)
                div.p-50
                  ejs-button(v-on:click.native="newEventType()" :content="'+ Nouveau type d’évènement'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
      b-row.mb-50(v-show="(data.eventType && data.eventType.isDependent) || data.companyId")
        b-col.py-0(cols='12')
          b-form-group(label='Client' label-for='company')
            ejs-dropdownlist(id="company" name="company" placeholder="Selectionnez un client" :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="companiesList" :fields="{ text: 'name', value: 'id' }" v-model="data.companyId" @change='changeCompany')
              template(v-slot:footerTemplate)
                div.p-50
                  ejs-button(v-on:click.native="newCompany()" :content="'+ Nouveau client'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
      b-row.mb-50(v-show="data.eventType && data.eventType.isDependent")
        b-col.py-0(cols='12')
          b-form-group(label='Affaire *' label-for='affair')
            ejs-dropdownlist(id="affair" name="affair" placeholder="Selectionnez une affaire" :itemTemplate="'itemTemplate'" :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="affairsListFiltered" :fields="{ text: 'displayLabel', value: 'id' }" v-model="data.affairId" @change='changeAffair')
              template(v-slot:itemTemplate='{data}')
                .d-flex
                  div.pin-color(:style="'background-color:'+(data.color!=='' ? data.color : '#0c3571')+' !important'")
                  div {{data.displayLabel}}
              template(v-slot:footerTemplate)
                div.p-50
                  ejs-button(v-on:click.native="newAffair()" :content="'+ Nouvelle affaire'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
      b-row.mb-50(v-show="data.eventTypeId && data.companyId && data.affairId")
        b-col.py-0(cols='12')
          b-form-group(label='Mission' label-for='mission')
            ejs-dropdownlist(id="mission" name="mission" placeholder="Selectionnez une mission" :showClearButton="true" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="missionsList" :fields="{ text: 'label', value: 'id' }" v-model="data.missionId" @change='changeMission')
      b-row.mb-50
        b-col.py-0(cols='12')
          b-form-group(label='Nom' label-for='name')
            ejs-textbox(id="name" v-model='data.name' placeholder="Titre de l'évènement")
      b-row.mb-50
        b-col.py-0(cols='6')
          b-form-group(label='Début' label-for='startDate') 
            ejs-datetimepicker(id="startDate" locale='fr' v-model="data.startDate" :firstDayOfWeek="1" placeholder="JJ/MM/AAAA HH:MM"  @change='changeDate')
        b-col.py-0(cols='6')
          b-form-group(label='Fin' label-for='endDate') 
            ejs-datetimepicker(id="endDate" locale='fr' v-model="data.endDate" :firstDayOfWeek="1" placeholder="JJ/MM/AAAA HH:MM"  @change='changeDate')
      b-row.mb-0
        b-col.py-0(cols='6')
          b-form-group(label='Durée en heure / jour' label-for='hoursDay') 
            ejs-numerictextbox(id="hoursDay" :enabled="!durationDisabled" :step="0.25" :format='"##.## h"' v-model="data.hoursDay"  @change='changeHoursDay')
    div.px-1(v-show="tabActive == 'details'")
      b-row.mb-50.mt-1
        b-col.py-0(cols='12')
          b-form-group(label='Description/Note' label-for='comments')
            ejs-textbox(id="comments" :multiline="true" v-model='data.comments' placeholder="Description de votre évènement" style="min-height: 150px;")
    div.px-1(v-show="tabActive == 'settings'")
      b-row.mb-50.mt-1
        //- b-col.py-0.d-flex(cols='12')
        //-     span(class="link_icon material-icons-outlined m-0 mr-50 pt-1" style="font-size:15px")
        //-         | lock
        //-     b-form-group(label="Interdire les autres utilisateurs de modifier cet évènement" label-cols="10")
        //-         b-form-checkbox(v-model="data.isLocked" id="isLocked" class="mr-0" name="isLocked" switch inline @change="changeIsLocked")
        //- b-col.py-0.d-flex(cols='12')
        //-     span(class="link_icon material-icons-outlined m-0 mr-50 pt-1" style="font-size:15px")
        //-         | visibility_off
        //-     b-form-group(label="Masquer le détail de cet évènement et le bloquer auprès des autres utilisateurs" label-cols="10")
        //-         b-form-checkbox(v-model="data.isHidden" id="isHidden" class="mr-0" name="isHidden" switch inline @change="changeIsHidden")

        b-col.py-0.mb-1.d-flex.justify-content-between(cols='12')
          div.d-flex.align-items-start
            span.material-icons-outlined.mt-25.mr-50(style="font-size:15px") visibility_off
            span.mx-1(style="flex:1") Afficher uniquement sur mon agenda {{ data.isPersonal }}
          div
            ejs-switch(ref="toggleSwitchIsPersonal" id="isPersonal" name="isPersonal" :checked="!data.isPersonal" offLabel="Non" onLabel="Oui" :created="createdIsPersonal")
        b-col.py-0.mb-1.d-flex.justify-content-between(cols='12')
          div.d-flex.align-items-start 
            span.material-icons-outlined.mt-25.mr-50(style="font-size:15px") lock
            span.mx-1(style="flex:1") Interdire les autres utilisateurs de modifier cet évènement
          div
            ejs-switch(ref="toggleSwitchIsLocked" id="isLocked" name="isLocked" :checked="!data.isLocked" offLabel="Non" onLabel="Oui" :value="true" :created="createdIsLocked")
        b-col.py-0.mb-0.d-flex.justify-content-between(cols='12')
          div.d-flex.align-items-start
            span.material-icons-outlined.mt-25.mr-50(style="font-size:15px") visibility_off
            span.mx-1(style="flex:1") Masquer le détail de cet évènement et le bloquer auprès des autres utilisateurs
          div
            ejs-switch(ref="toggleSwitchIsHidden" id="isHidden" name="isHidden" :checked="!data.isHidden" offLabel="Non" onLabel="Oui" :value="true" :created="createdIsHidden")
  </template>
<script>
import { mapGetters, mapActions } from "vuex";
import { SwitchComponent } from "@syncfusion/ej2-vue-buttons";
import { enableRipple } from "@syncfusion/ej2-base";
enableRipple(true);

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

export default {
  components: {
    "ejs-switch": SwitchComponent,
  },
  data() {
    return {
      data: null,
      tabActive: "general",
      durationDisabled: false,
      keyHoursDay: 0,
    };
  },
  computed: {
    ...mapGetters([
      "eventTypesList",
      "companiesList",
      "affairsList",
      "missionsList",
      "userCollaborator",
    ]),
    affairsListFiltered() {
      if (this.data.companyId) {
        let listFiltered = this.affairsList.filter(
          (el) => el.company && el.company.id == this.data.companyId
        );
        if (listFiltered.length == 1) {
          this.data.affair = listFiltered[0];
          this.data.affairId = listFiltered[0].id;
          //this.eventForm.name = this.eventForm.name + ' / ' +listFiltered[0].name
        }
        return listFiltered;
      } else {
        return this.affairsList;
      }
    },
  },
  beforeMount() {
    this.changeDate();
  },
  mounted() {
    this.$forceUpdate();
  },
  methods: {
    createdIsPersonal: function (args) {
      this.$refs.toggleSwitchIsPersonal.toggle();
    },
    createdIsLocked: function (args) {
      this.$refs.toggleSwitchIsLocked.toggle();
    },
    createdIsHidden: function (args) {
      this.$refs.toggleSwitchIsHidden.toggle();
    },
    changeEventType(event) {
      this.data.eventType = event.itemData;
      this.data.name = event.itemData.label;
      this.data.cls =
        "background-color:" + event.itemData.color + " !important";
      this.data.color = event.itemData.color;
      if (!event.itemData.isDependent) {
        this.data.company = null;
        this.data.companyId = null;
        this.data.affair = null;
        this.data.affairId = null;
      }
      this.$forceUpdate();
    },
    changeCompany(event) {
      this.data.company = event.itemData;
      this.data.companyId = event.itemData.id;
      this.data.name = event.itemData.name;
      this.data.affair = null;
      this.data.affairId = null;
      this.$forceUpdate();
    },
    changeAffair(event) {
      if (event.itemData) {
        this.data.affair = event.itemData;
        this.data.companyId = event.itemData.company.id;
        this.data.name = this.data.name + " / " + event.itemData.displayLabel;
        this.data.cls =
          "background-color:" +
          (event.itemData.color
            ? event.itemData.color
            : this.data.eventType.color) +
          " !important";
        this.data.color = event.itemData.color
          ? event.itemData.color
          : this.data.eventType.color;
      }
    },
    changeMission(event) {
      // this.data.mission = event.itemData;
      // this.$forceUpdate();
    },
    changeDate() {
      const date1 = dayjs(this.data.endDate);
      const diff = date1.diff(this.data.startDate, "hours", true);
      if (diff >= 24) {
        this.data.duration = date1.diff(this.data.startDate, "hours", true);
        // this.data.hoursDay = this.data.hoursDay ? this.data.hoursDay : this.userCollaborator ? this.userCollaborator.hoursByDay : 7
        this.durationDisabled = false;
      } else {
        this.data.duration = date1.diff(this.data.startDate, "hours", true);
        this.data.hoursDay = date1.diff(this.data.startDate, "hours", true);
        this.durationDisabled = true;
      }
      this.$forceUpdate();
    },
    changeHoursDay(event) {
      this.data.hoursDay = event.value;
      this.$forceUpdate();
    },
    newEventType() {
      this.$router.push({ path: "/planning/settings/event-types" });
    },
    newCompany() {
      this.$router.push({ path: "/directory/new-company/clients/5" });
    },
    newAffair() {
      this.$router.push({
        name: "new-affair",
        params: {
          id: 0,
          companyId: this.eventForm.company.id,
          title: "Nouvelle affaire",
          tips: "Nouvelle affaire",
          routeOrigine: "affairs",
        },
      });
    },
  },
};
</script>
<style lang="scss">
.e-schedule-dialog .e-dlg-header-content {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom: 0px !important;
}

.e-schedule-dialog .e-dlg-content {
  padding: 0px !important;
}

.e-schedule-dialog .e-footer-content {
  margin-top: 0px !important;
}
</style>
