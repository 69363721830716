<template lang="pug">
.h-100(v-if="dataLoaded") 
    ejs-schedule(ref="ScheduleObj" :timeScale='timeScale' :enableAllDayScroll="enableAllDayScroll" :height="'100%'" locale="fr" :rowAutoHeight="true" :firstDayOfWeek="firstDayOfWeek" :currentView="currentView" :selectedDate='selectedDate' :eventSettings='eventSettings' :eventRendered='onEventRendered' :quickInfoTemplates="quickInfoTemplates"  :editorTemplate='editorTemplate' :popupOpen="onPopupOpen" :popupClose="onPopupClose" :actionBegin="onActionBegin" :editorFooterTemplate="editorFooterTemplate" :actionComplete="onActionComplete" :cellDoubleClick="onCellDoubleClick" :dragStart="onDragStart" :resizeStart="onResizeStart" style='border-radius: 7px;' )
</template>
<script>
import { mapGetters, mapActions } from "vuex";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Resize,
  DragAndDrop,
  Timezone,
} from "@syncfusion/ej2-vue-schedule";
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
import { isMobile as checkIfMobile } from "@/types/api-orisis/library/GlobalOperations.ts";

import quickInfoTemplateHeader from "@/components/planning/scheduler/QuickInfoTemplateHeader.vue";
import quickInfoTemplateContent from "@/components/planning/scheduler/QuickInfoTemplateContent.vue";
import quickInfoTemplateFooter from "@/components/planning/scheduler/QuickInfoTemplateFooter.vue";
import editorTemplate from "@/components/planning/scheduler/EditorTemplate.vue";
import editorFooterTemplate from "@/components/planning/scheduler/EditorFooterTemplate.vue";

export default {
  components: {
    "ejs-schedule": ScheduleComponent,
    "ejs-datepicker": DatePickerComponent,
  },
  props: {
    currentDate: {
      type: Date,
      default: () => new Date(),
    },
    currentView: {
      type: String,
      default: "Month",
    },
    dataScheduler: {
      type: Array,
      default: () => [],
    },
    viewMode: {
      type: String,
      default: "compare",
    },
  },
  provide: {
    schedule: [Day, Week, WorkWeek, Month, Agenda, Resize, DragAndDrop],
  },
  data() {
    return {
      title: "",
      name: "dashboard",
      key: 0,
      dataLoaded: false,
      firstDayOfWeek: 1,
      enableAllDayScroll: true,
      selectedDate: this.currentDate,
      timeScale: {
        enable: true,
        interval: 60,
        slotCount: 4,
      },
      eventSettings: {
        dataSource: this.dataScheduler,
        fields: {
          id: "id",
          isAllDay: { name: "isAllDay", title: "Toute la journée" },
          subject: { name: "name", title: "Nom" },
          location: { name: "Location", title: "Event Location" },
          duration: { name: "duration", title: "Durée en heure / jour" },
          description: { name: "comments", title: "Détails" },
          startTime: { name: "startDate", title: "Début" },
          endTime: { name: "endDate", title: "Fin" },
          eventTypeId: { name: "eventTypeId", title: "Type d'évènement" },
          companyId: { name: "eventTypeId", title: "Client" },
          affairId: { name: "affairId", title: "Affaire" },
          hoursDay: { name: "hoursDay", title: "Heures par jour" },
          isPersonal: { name: "isPersonal", title: "Uniquement sur l'agenda" },
          type: { name: "type", title: "Type" },
        },
        allowAdding: true,
        allowDeleting: true,
        allowEditing: true,
      },
      isMobile: checkIfMobile(),
      quickInfoTemplates: {
        header: function (e) {
          return {
            template: quickInfoTemplateHeader,
          };
        },
        content: function (e) {
          return {
            template: quickInfoTemplateContent,
          };
        },
        footer: function (e) {
          return {
            template: quickInfoTemplateFooter,
          };
        },
      },
      editorTemplate: function (e) {
        return {
          template: editorTemplate,
        };
      },
      editorFooterTemplate: function () {
        return {
          template: editorFooterTemplate,
        };
      },
    };
  },
  async mounted() {
    this.eventSettings.dataSource = this.dataScheduler;
    this.dataLoaded = true;
    window.addEventListener("resize", this.updateIsMobile);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateIsMobile);
  },
  computed: {
    ...mapGetters([
      "userCollaboratorId",
      "workspaceSelected",
      "eventTypesList",
      "affairsList",
    ]),
  },
  methods: {
    ...mapActions([
      "createEvent",
      "updateEvent",
      "deleteEvents",
      "updateActivity",
    ]),
    updateIsMobile() {
      this.isMobile = checkIfMobile();
    },
    onDragStart: function (args) {
      if (args.data.type == "reporting" || args.data.type == "intervention") {
        args.cancel = true;
      }
    },
    onResizeStart: function (args) {
      if (
        args.data.type == "reporting" ||
        args.data.type == "reminder" ||
        args.data.type == "intervention"
      ) {
        args.cancel = true;
      }
    },
    onCellDoubleClick: function (args) {
      if (this.checkModule(6) || this.checkModule(7)) {
        args.cancel = true;
      }
    },
    onActionBegin: function (args) {
      if (args.requestType == "eventRemove") {
        this.deleteEvents({
          eventIds: args.data.map((event) => event.id),
        });
      } else if (args.requestType == "eventCreate") {
        if (args.data.id) {
          args.data.createdBy = this.userCollaboratorId;
          this.createEvent({
            event: {
              ...args.data,
              id: 0,
              collaboratorIds: [this.userCollaboratorId],
            },
            route: "collaborator",
          });
        } else {
          args.data.forEach((element) => {
            element.createdBy = this.userCollaboratorId;
            this.createEvent({
              event: {
                ...element,
                id: 0,
                collaboratorIds: [this.userCollaboratorId],
              },
              route: "collaborator",
            });
          });
        }
      } else if (args.requestType == "eventChange") {
        if (args.data.id) {
          if (args.data.type == "reminder") {
            args.data.updatedBy = this.userCollaboratorId;
            // A VERIFIER (BAPTISTE) - Verifier le rafraichissement de l'activité
            this.updateActivity({
              activity: {
                ...args.data.data,
                reminderDate: dayjs(args.data.startDate).format(
                  "YYYY-MM-DDTHH:mm:ss"
                ),
              },
              route: "collaborator",
            });
            args.data.type = "reminder";
          } else if (args.data.type == "planning") {
            args.data.updatedBy = this.userCollaboratorId;

            this.updateEvent({
              event: { ...args.data },
              route: "collaborator",
            });
            args.data.type = "planning";
          } else {
            args.data.createdBy = this.userCollaboratorId;
            this.createEvent({
              event: {
                ...args.data,
                collaboratorIds: [this.userCollaboratorId],
              },
              route: "collaborator",
            });
          }
        } else {
          args.data.forEach((element) => {
            if (element.type == "planning") {
              element.updatedBy = this.userCollaboratorId;
              this.updateEvent({
                event: { ...element },
                route: "collaborator",
              });
              element.type = "planning";
            } else {
              element.createdBy = this.userCollaboratorId;
              this.createEvent({
                event: {
                  ...element,
                  collaboratorIds: [this.userCollaboratorId],
                },
                route: "collaborator",
              });
            }
          });
        }
      }
    },
    onActionComplete(args) {
      if (
        args.requestType == "viewNavigate" ||
        args.requestType == "dateNavigate"
      ) {
        let scheduleObj = this.$refs.ScheduleObj.ej2Instances;
        let currentDatesView = scheduleObj.getCurrentViewDates();
        let currentView = this.currentView;
        if (currentDatesView.length == 1) {
          currentView = "Day";
        } else if (currentDatesView.length == 5) {
          currentView = "WorkWeek";
        } else if (currentDatesView.length == 7) {
          currentView = "Week";
        } else if (
          currentDatesView.length > 7 &&
          currentDatesView.length < 60
        ) {
          currentView = "Month";
        } else {
          currentView = "Year";
        }
        this.$emit("changeCurrentView", currentView);

        let startDate = dayjs(currentDatesView[0]).format("YYYY-MM-DD");
        let endDate = dayjs(
          currentDatesView[currentDatesView.length - 1]
        ).format("YYYY-MM-DD");
        if (args.requestType == "dateNavigate") {
          this.$emit("changeViewDates", {
            startDate: startDate,
            endDate: endDate,
            force: true,
          });
        } else {
          this.$emit("changeViewDates", {
            startDate: startDate,
            endDate: endDate,
            force: false,
          });
        }
      }
    },

    checkModule(moduleId) {
      // CORRECTIONS AVEC FONCTIONS
      return true;
      // return this.$store.getters.workspaceSelected.modules.find(
      //   (el) => el.id == moduleId
      // );
    },
    onPopupOpen: function (args) {
      if (
        args.type === "Editor" &&
        (args.data.type == "reporting" ||
          args.data.type == "reminder" ||
          args.data.type == "intervention")
      ) {
        args.cancel = true;
      }
      // else if (args.type === "Editor") {
      //     let scheduleObj = this.$refs.ScheduleObj.ej2Instances
      // const saveButton = args.element.querySelector("#Save");
      // const cancelButton = args.element.querySelector("#Cancel");
      // saveButton.onClick = () => {
      //     // const data = {
      //     //     Id: args.data.Id,
      //     //     Subject: args.element.querySelector("#Subject").value,
      //     //     StartTime: args.element.querySelector("#StartTime").ej2_instances[0].value,
      //     //     EndTime: args.element.querySelector("#EndTime").ej2_instances[0].value,
      //     //     IsAllDay: args.element.querySelector("#IsAllDay").checked,
      //     // };
      //     // if (args.target?.classList.contains("e-appointment")) {
      //     //     scheduleObj.saveEvent(data, "Save");
      //     // } else {
      //     //     data.Id = scheduleObj.getEventMaxID();
      //     //     scheduleObj.addEvent(data);
      //     // }
      //     scheduleObj.closeEditor();
      // };
      // cancelButton.onclick = () => {
      //     scheduleObj.closeEditor();
      // };
      // }
    },
    onPopupClose: function (args) {
      if (
        args.type === "Editor" &&
        !args.event.srcElement.className.includes("e-event-delete") &&
        !args.event.srcElement.className.includes("e-event-cancel") &&
        !args.event.srcElement.className.includes("e-icon-dlg-close") &&
        !args.event.srcElement.className.includes("e-dlg-closeicon-btn")
      ) {
        args.data.color = "";
        let eventType = args.element.querySelector("#eventType");
        if (eventType) {
          args.data.eventTypeId = eventType.ej2_instances[0].value;
          let eventTypeDetails = this.eventTypesList.find(
            (el) => el.id == args.data.eventTypeId
          );
          args.data.color = eventTypeDetails ? eventTypeDetails.color : "";
        } else {
          args.data.eventTypeId = 0;
        }

        let company = args.element.querySelector("#company");
        if (company) {
          args.data.companyId = company.ej2_instances[0].value;
        } else {
          args.data.companyId = 0;
        }

        let affair = args.element.querySelector("#affair");
        if (affair) {
          args.data.affairId = affair.ej2_instances[0].value;
          let affairDetails = this.affairsList.find(
            (el) => el.id == args.data.affairId
          );
          if (args.data.affairId && args.data.affairId > 0) {
            args.data.color = affairDetails ? affairDetails.color : "";
          }
        } else {
          args.data.affairId = null;
        }
        let mission = args.element.querySelector("#mission");
        if (mission) {
          args.data.missionId = mission.ej2_instances[0].value;
        } else {
          args.data.missionId = 0;
        }
        let name = args.element.querySelector("#name");
        args.data.name = name.value;
        let startDate = args.element.querySelector("#startDate");
        args.data.startDate = startDate.ej2_instances[0].value;
        let endDate = args.element.querySelector("#endDate");
        args.data.endDate = endDate.ej2_instances[0].value;
        let hoursDay = args.element.querySelector("#hoursDay");
        args.data.hoursDay = hoursDay.ej2_instances[0].value;
        args.data.duration =
          dayjs(args.data.endDate).diff(args.data.startDate, "hours", true) < 24
            ? dayjs(args.data.endDate).diff(args.data.startDate, "hours", true)
            : dayjs(args.data.endDate).diff(args.data.startDate, "days", true);

        let isLocked = args.element.querySelector("#isLocked");
        args.data.isLocked = isLocked.ej2_instances[0].checked;
        let isHidden = args.element.querySelector("#isHidden");
        args.data.isHidden = isHidden.ej2_instances[0].checked;
        let isPersonal = args.element.querySelector("#isPersonal");
        args.data.isPersonal = isPersonal.ej2_instances[0].checked;

        let comments = args.element.querySelector("#comments");
        args.data.comments = comments.ej2_instances[0].value;

        let type = args.element.querySelector("#type");
        args.data.type = "planning";

        // if(args.data.type =='planning'){
        //     args.data.updatedBy = this.userCollaboratorId
        //     this.updateEvent({...args.data, collaboratorIds:[this.userCollaboratorId]})
        //     args.data.type= 'planning'
        // }else{
        //     args.data.createdBy = this.userCollaboratorId
        //     this.createEvent({...args.data, collaboratorIds:[this.userCollaboratorId]})
        // }
      }
    },
    onEventRendered: function (args) {
      let scheduleObj = this.$refs.ScheduleObj;
      if (!args.element) {
        return;
      }
      if (scheduleObj.ej2Instances.currentView === "Agenda") {
        args.element.firstChild.style.borderLeftColor = args.data.color;
      } else {
        if (args.data.type == "reminder") {
          args.element.style.color = "#333333";
          args.element.style.border = "1px solid " + args.data.color;
          args.element.style.backgroundColor = "#ffffff";
        } else if (
          args.data.type == "reporting" ||
          args.data.type == "intervention"
        ) {
          // args.element.classList.add('bg-secondary') //args.data.color;
          // args.element.style.opacity = 0.8
          args.element.style.backgroundColor = args.data.color + "85";
          // args.element.style.background = "repeating-linear-gradient(45deg, "+args.data.color+", "+args.data.color+" 6px, "+args.data.color+"c2 6px, "+args.data.color+"c2 12px)";
        } else {
          args.element.style.backgroundColor = args.data.color;
        }
      }
    },
  },
};
</script>
<style>
.e-schedule .e-subject {
  text-align: left !important;
  padding-left: 5px !important;
}

@media (max-width: 767px) {
  .e-new-event {
    display: none;
  }
}
</style>
