<template lang="pug">
.bg-light(v-if="data.type" :class="data.type=='reminder' ? 'border-danger' : data.type=='reporting' ? 'border-secondary' : ''" :style="data.type=='planning' && eventType ? 'border-bottom: 1px solid '+data.color+' !important' : ''" style="border-top: 0px solid #ddd !important; border-right: 0px solid #ddd !important; border-left: 0px solid #ddd !important; border-bottom: 1px solid #ddd")
    .ml-2.py-50
        .d-flex.align-items-center.justify-content-between.mr-3
          h4.mb-0(style="text-transform: capitalize;") {{ dateStr }}
          span.link_icon.material-icons-outlined.text-primary.m-0.ml-50(v-if="data.status < 3" style='font-size:19px; cursor: pointer;' @click="data.type === 'intervention' ? editIntervention() : editReport()")
                | edit
        feather-icon.close-icon.cursor-pointer(icon='XIcon' size='24' style="position: absolute;right: 5;top: 5px;" @click='buttonClickActions')
        .d-flex.align-items-center(style='width:100%;height:100%;') 
            span.e-chips(:class="data.type=='reminder' ? '' : 'text-white'" :style="'padding: 0 4px 0 4px; border-radius: 4px; background-color: '+( data.type=='reminder' ? '#ffffff' : data.color ) +' !important; color:'+( data.type=='reminder' ? data.color : '#ffffff' )+' !important; border:1px solid ' + data.color + ' !important'") {{ data.type=='reminder' ? 'Rappel' : data.type=='planning' ? 'Planning' : data.type=='reporting' ? 'Reporting' : data.type=='intervention' ? 'Intervention' : '' }}
            div.d-flex.align-items-center.justify-content-center(:class="data.status <= 1 ? 'bg-purple' : data.status == '2' ? 'bg-warning' : 'bg-success'" style='border-radius: 50%; border:1px solid #ffffff; width: 20px; height: 20px; margin-left: 5px;' v-b-tooltip.hover.top="data.status <= 1 ? 'Brouillon' : data.status == '2' ? 'A valider' : 'Validé'")
                span.material-icons.text-white(style='font-size: 0.7rem;') {{ data.status <=1 ? 'edit' :  data.status == '2' ? 'schedule' : 'done' }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { reportingStatus } from "@/types/api-orisis/enums/enums";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

export default {
  data() {
    return {
      dateStr: null,
      data: null,
      statusClass: null,
      eventType: null,
    };
  },
  computed: {
    ...mapGetters(["eventTypesList"]),
  },
  beforeMount() {
    this.dateStr = this.data.startDate
      ? dayjs(this.data.startDate).format("dddd D MMMM YYYY")
      : "";
    if (this.data.data) {
      this.statusClass = this.data.status
        ? reportingStatus.find((elem) => elem.value == this.data.status).class
        : "";
      this.eventType =
        this.data.data.eventTypeId &&
        this.eventTypesList.find(
          (eventType) => eventType.id == this.data.data.eventTypeId
        )
          ? this.eventTypesList.find(
              (eventType) => eventType.id == this.data.data.eventTypeId
            )
          : "";
    }
  },
  methods: {
    buttonClickActions: function (e) {
      const scheduleObj = this.$parent;
      scheduleObj.closeQuickInfoPopup();
    },
    editReport() {
      const scheduleObj = this.$parent;
      scheduleObj.closeQuickInfoPopup();
      this.$router.push({
        name: "edit-report",
        params: {
          routeOrigine: "my-agenda",
          id: this.data.data.reportId,
          title: "Modifier mon reporting",
        },
      });
    },
    editIntervention() {
      const scheduleObj = this.$parent;
      scheduleObj.closeQuickInfoPopup();
      this.$router.push({
        name: "edit-intervention",
        params: {
          routeOrigine: "my-agenda",
          id: this.data.data.id,
          title: "Modifier mon intervention",
        },
      });
    },
  },
};
</script>
